import React, { Component } from "react";
import { Textfit } from 'react-textfit';

export default class Preview extends Component {
  constructor() {
    super();
    this.state = {
      position: "0 0",
      dragging: false
    };
  }

  handleMouseDown = () => {
    this.setState({
      dragging: true
    });
  };

  handleMouseUp = () => {
    this.setState({
      dragging: false
    });
  };

  handleDrag = e => {
    e.preventDefault();
    if (this.state.dragging === true) {
      let x = e.movementX;
      let y = e.movementY;
      this.setState(prevState => {
        let pos = prevState.position.split(" ");
        let newX = (parseInt(pos[0]) + x).toString();
        let newY = (parseInt(pos[1]) + y).toString();

        return { position: newX + "px " + newY + "px" };
      });
    }
  };

  getSize() {
    switch (this.props.canvasSize) {
      case "linkedin":
        return {
          width: 1104,
          height: 736
        };
      case "facebook":
        return {
          width: 1200,
          height: 630
        };
        case "instagram":
          return {
            width: 1080,
            height: 1080
          };
      case "twitter":
        return {
          width: 506 * 2,
          height: 253 * 2
        };
      default:
        break;
    }
  }

  render() {
    return (
      <div className="preview-wrapper">
        <div
          onMouseMove={this.handleDrag}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          style={this.getSize()}
          className="preview"
          id="preview"
        >
          <div
            className="bg"
            style={{
              backgroundPosition: this.state.position,
              backgroundSize: this.props.bgSize + "%",
              backgroundImage: `url("${this.props.server}/upload/${this.props.image}")`,
            }}
          ></div>
          <div className={this.props.cat + " icon"}>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/icons/trainingsprogramma.svg"}
            ></img>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/icons/interim-planner.svg"}
            ></img>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/icons/ondersteuning.svg"}
            ></img>
          </div>
          <img
            className="logo"
            alt=""
            src={process.env.PUBLIC_URL + "/logo.png"}
          ></img>
          <Textfit className="text" max="50">
          {this.props.text}
          </Textfit>

        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { FilePond } from "react-filepond";


export default class Inputs extends Component {

  render() {
    let i = 1;
    return (
      <div className="inputs-wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="input"> 
                <label>{i++}. Afmeting</label>
                <select
                  onChange={this.props.setCanvas}
                  value={this.props.canvasSize}
                >
                  <option value="linkedin">LinkedIn</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="twitter">Twitter</option>
                </select>
              </div>

              <div className="input">
                <label>{i++}. Categorie</label>
                <select onChange={this.props.setCat}>
                  <option value="trainingprogramma">Trainingprogramma</option>
                  <option value="interim-planner">Interim Planner</option>
                  <option value="ondersteuning">Ondersteuning</option>
                </select>
              </div>

              <div className="input">
                <label>{i++}. Upload achtergrond</label>
                <FilePond
                  onprocessfile={this.props.handleUpload}
                  server={this.props.server}
                />
              </div>
            </div>
            <div className="col">
              <div className="input">
                <label>{i++}. Achtergrond grootte</label>
                <input
                  type="range"
                  min="100"
                  max="200"
                  value={this.props.bgSize}
                  onChange={this.props.handleSlider}
                ></input>
              </div>

              <div className="input">
                <label>{i++}. Tekst</label>

                <textarea rows="2"
                  value={this.props.text}
                  onChange={this.props.handleText}
                ></textarea>

              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ borderTop: "1px solid #bfbfbf", paddingTop: 30 }}
          >
            <button onClick={this.props.downloadImage}>
              Afbeelding opslaan
            </button>
          </div>
        </div>
      </div>
    );
  }
}

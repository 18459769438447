import React from "react";

export default function Header() {
  return (
    <header>
      <div className="container">
        <div className="row justify-content-between">

            <p>Yourplannerz social media tool</p>

       
            <img alt="" src={process.env.PUBLIC_URL + "/mk.png"}></img>
          
        </div>
      </div>
    </header>
  );
}
